header{
	height: 100px;
	background-color: #efefef;
	.logo{
		display: inline-block;
		vertical-align: top;
		width: 230px;
		height: 112px;
		background-color: #fff;
		box-shadow: 0 4px 40px rgba(0, 0, 0, 0.35);
		a{
			display: block;
			padding-left: 23px;
		}
	}
	nav{
		display: inline-block;
		vertical-align: top;
		margin-top: 40px;
  		margin-left: 61px;
		ul{
			li{
				display: inline-block;
				vertical-align: top;
				margin-right: 23px;
				&:last-of-type{
					margin-right: 0;
				}
				a{
					font-size: 14px;
					font-weight: 600;
					text-transform: uppercase;
					color: #3b3b3b;
					position: relative;
					&:before{
						position: absolute;
						content: '';
						bottom: -5px;
						right: 0;
						left: 0;
						height: 3px;
						background: #2f5c9a;
						display: none;
					}
					&:hover{
					color: #2f5c9a;	
						&:before{
							display: block;
						}
					}
				}
			}
		}
	}
	.phone{
		display: inline-block;
		vertical-align: top;
		position: relative;
		padding-left: 30px;
		color: #3b3b3b;
		font-size: 24px;
		font-weight: 500;
		text-transform: uppercase;
		float: right;
  		margin-right: 15px;
  		margin-top: 31px;

		&:before{
			position: absolute;
			content: '';
			top: 9px;
			left: 0;
			background: url(../images/ico-phone.png) center center no-repeat;
			width: 18px;
			height: 18px;
		}
	}
}
.promo{
	background: url(../images/bg-promo.jpg) center center no-repeat;
	background-size: cover;
	h1{
		color: #fff;
		font-family: "Gotham Pro";
		font-size: 55px;
		line-height: 66px;
		font-weight: 700;
		letter-spacing: 5.5px;
		text-align: center;
		text-transform: uppercase;
		padding: 100px 0 130px;
	}
}
.garanty{
	ul{
		font-size: 0px;
		margin-top: 99px;
		li{
			display: inline-block;
			vertical-align: top;
			position: relative;
			width: 165px;
			margin-right: 30px;
			text-align: center;
			color: #3b3b3b;
			font-size: 14px;
			font-weight: 700;
			&:last-of-type{
				margin-right: 0;
			}
			&:before{
				position: absolute;
				content: '';
				top: -181px;
				left: 0;
				width: 165px;
				height: 165px;
				border-radius: 50%;
				z-index: 3;
				box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
			}
			&:after{
				position: absolute;
				content: '';
				width: 195px;
				height: 195px;
				background: rgba(255,255,255,0.4);
				top: -196px;
				left: -15px;
				border-radius: 50%;
			}
			&:nth-of-type(1){
				&:before{
					background: url(../images/ico-garanty-1.png) center center no-repeat, #fff;		
				}
				
			}
			&:nth-of-type(2){
				&:before{
					background: url(../images/ico-garanty-2.png) center center no-repeat, #fff;		
				}
				
			}
			&:nth-of-type(3){
				&:before{
					background: url(../images/ico-garanty-3.png) center center no-repeat, #fff;		
				}
				
			}
			&:nth-of-type(4){
				&:before{
					background: url(../images/ico-garanty-4.png) center center no-repeat, #fff;		
				}
				
			}
			&:nth-of-type(5){
				&:before{
					background: url(../images/ico-garanty-5.png) center center no-repeat, #fff;		
				}
				
			}
			&:nth-of-type(6){
				&:before{
					background: url(../images/ico-garanty-6.png) center center no-repeat, #fff;		
				}
				
			}
		}
	}
	.btn{
		display: block;
		margin: 47px auto 50px;
		width: 263px;
		height: 55px;
		line-height: 55px;
	} 
}
.product{
	background-color: #efefef;
	text-align: center;
	padding-top: 25px;
	ul{
		font-size: 0px;
		margin-top: 25px;
		li{
			display: inline-block;
			vertical-align: top;
			width: 360px;
			margin-right: 30px;
			text-align: left;
			margin-bottom: 50px;
			&:nth-of-type(3n){
				margin-right: 0;
			}
			.img-wr{
				float: left;
				width: 152px;
				height: 309px;
				padding-top: 38px;
				background: url(../images/bg-product-item.png) center center no-repeat;
				text-align: center;
				margin-right: 27px;
				position: relative;
				 &:before{
					position: absolute;
					content: '';
					box-shadow: 0 4px 40px rgba(0, 0, 0, 0.71);
					top: 0;
					left: 1px;
					width: 150px;
					height: 309px;
					border-radius: 23px;
				} 
			}
			.item-ttt{
				color: #d83b2b;
				font-size: 18px;
				font-weight: 700;
				margin-top: 11px;
				margin-bottom: 11px;
			}
			.desc{
				color: #2e2e2e;
				font-size: 14px;
				line-height: 17px;
				font-weight: 400;
			}
			.btn{
				width: 164px;
				height: 34px;
				line-height: 34px;
				font-size: 13px;
				margin-top: 21px;
			}
		}
	}
}
.benefits{
	text-align: center;
	padding-top: 25px;
	padding-bottom: 25px;
	ul{
		font-size: 0px;
		text-align: left;
		margin-top: 25px;
		li{
			display: inline-block;
			text-align: center;
			color: #3b3b3b;
			font-size: 15px;
			font-weight: 700;
			width: 165px;
			padding-top: 189px;
			margin-right: 30px;
			position: relative;
			&:last-of-type{
				margin-right: 0;
			}
			&:before{
				position: absolute;
				content: '';
				left: 0;
				top: 0;
				width: 140px;
				height: 140px;
				background-color: #fff;
				border-radius: 50%;
				border-width: 12px;
				border-color: #cdcdcd  #cdcdcd #2f5c9a #2f5c9a;
				border-style: solid;
			}
			&:after{
				position: absolute;
				content: '';
				left: 0;
				top: 0;
				width: 164px;
				height: 164px;
			}
			&:nth-of-type(1){
				&:before{
					transform: rotate(-45deg);
				}
				&:after{
					background: url(../images/ico-benefits-1.png) center center no-repeat;
				}
			}
			&:nth-of-type(2){
				&:before{
					transform: rotate(45deg);
				}
				&:after{
					background: url(../images/ico-benefits-2.png) center center no-repeat;
				}
			}
			&:nth-of-type(3){
				&:before{
					transform: rotate(135deg);
				}
				&:after{	
					background: url(../images/ico-benefits-3.png) center center no-repeat;
				}
			}
			&:nth-of-type(4){
				&:before{
					transform: rotate(-45deg);
				}
				&:after{
					background: url(../images/ico-benefits-4.png) center center no-repeat;
				}
			}
			&:nth-of-type(5){
				&:before{
					transform: rotate(45deg);
				}
				&:after{
					background: url(../images/ico-benefits-5.png) center center no-repeat;
				}
			}
			&:nth-of-type(6){
				&:before{
					transform: rotate(135deg);
				}
				&:after{
					background: url(../images/ico-benefits-6.png) center center no-repeat;
				}
			}
		}
	}
}
.testimonials{
	background-color: #efefef;
	text-align: center;
	padding-top: 25px;
	padding-bottom: 25px;
	.slider-wr{
		position: relative;
		.swiper-button-prev,
		.swiper-button-next{
			&:hover{
				opacity: 0.8;
			}
		}
		.swiper-button-prev{
			background: url(../images/img-left-btn.png);
			width: 15px;
			height: 29px;
			top: 102px;
			left: -15px;
		}
		.swiper-button-next{
			background: url(../images/img-right-btn.png);
			width: 15px;
			height: 29px;
			top: 102px;
			right: -15px;
		}
	}
	.swiper-container{
		margin: 30px auto 0;
		.swiper-wrapper{
			.swiper-slide{
				position: relative;
				padding-left: 20px;
				text-align: left;
				&:before{
					position: absolute;
					content: '';
					right: -15px;
					top: 34px;
					width: 1px;
					height: 127px;
					background-color: #2f5c9a;
				}
				.name{
					color: #2f5c9a;
					font-size: 21px;
					font-weight: 700;
				}
				.position{
					color: #1d1d1d;
					font-size: 19px;
					font-weight: 600;
				}
				.feedback{
					color: #1d1d1d;
					font-size: 15px;
					font-weight: 400;
					margin: 11px 0 10px;
				}
				.phone,
				.mail{
					color: #1d1d1d;
					font-size: 15px;
					font-weight: 600;
					position: relative;
					padding-left: 19px;
					display: inline-block;
					&:hover{
						&:after{
							right: 0;
						}
					}
					&:after{
						position: absolute;
						content:'';
						height: 1px;
						left: 0;
						right: 100%;
						background: #000;
						bottom: -2px;
						transition: right 0.2s;
					}
					&:before{
						position: absolute;
						content: '';
						left: 0;
						top: 3px;
						height: 14px;
						width: 14px;
					}
				}
				.phone{
					margin-bottom: 9px;
					&:before{
						background: url(../images/ico-phone-feedback.png) center center no-repeat;
					}
				}
				.mail{
					margin-bottom: 3px;
					&:before{
						background: url(../images/ico-mail-feedback.png) center center no-repeat;
					}
				}
			}

		}
	}
}
footer{
	background: #474747;
	padding-bottom: 23px;
	.left{
		.footer-menu{
			padding-top: 24px;
  			padding-left: 2px;
			li{
				display: inline-block;
				margin-right: 23px;
				a{
					color: #fff;
					font-size: 14px;
					font-weight: 600;
					text-transform: uppercase;
					&:hover{
						color: #2f5c9a;
					}
				}
				&:last-of-type{
					margin-right: 0;
				}
			}
		}
		.btn{
			width: 178px;
			height: 34px;
			line-height: 34px;
			font-size: 13px;
			margin-top: 24px;
		}
	}
	.right{
		float: right;
		padding-top: 15px;
		margin-right: 17px;
		.phone,
		.place{
			display: block;
			color: #fff;
			font-size: 18px;
			font-weight: 600;
			position: relative;
			padding-left: 35px;
		}
		.phone{
			margin-bottom: 17px;
			&:before{
				content: '';
				position: absolute;
				background: url(../images/ico-phone-footer.png) center center no-repeat;
				width: 20px;
				height: 20px;
				top: 4px;
				left: 3px;
			}
		}
		.place{
			&:before{
				content: '';
				position: absolute;
				background: url(../images/ico-place-footer.png) center center no-repeat;
				width: 14px;
				height: 21px;
				top: 3px;
				left: 4px;
			}
		}
	}
}
.partner{
	font-size: 0px;
	.pull-left{
		margin-right: 31px;
	}
	p{
		color: #000;
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 24px;
		line-height: 19px;
		&:before,
		&:after{
			clear: both;
			display: table;
			content: '';
		}
		strong{
			font-weight: 600;
		}
	}
	.mailus{
		position: relative;
		font-size: 16px;
		color: #2f5c9a;
		font-weight: 600;
		padding-left: 27px;
		&:before{
			position: absolute;
			content: '';
			top: 4px;
			left: 1px;
			background: url(../images/ico-mail-feedback.png) center center no-repeat;
			width: 14px;
			height: 14px;
		}
		a{
			color: #000;
			font-weight: 400;
		}
	}
}
.contact{
	font-size: 0px;
	h3{
		margin-bottom: 11px;
	}
	.phone,
	.mail{
		color: #3b3b3b;
		font-size: 20px;
		font-weight: 600;
		position: relative;
		padding-left: 32px;
		display: inline-block;
		position: relative;
		&:before{
			position: absolute;
			top: 7px;
			left: 2px;
			width: 14px;
			height: 14px;
			content: '';
		}
	}
	.phone{
		margin-bottom: 11px;
		&:before{
			background: url(../images/ico-phone-feedback.png) center center no-repeat;
		}
	}
	.mail{
		&:before{
			background: url(../images/ico-mail-feedback.png) center center no-repeat;
		}
	}
	form{
		input{
			color: #000;
			padding-left: 15px;
			font-size: 16px;
			font-weight: 400;
			border: none;
			width: 848px;
			height: 48px;
			background-color: #f5f5f5;
			margin-bottom: 20px;
		}
		textarea{
			width: 848px;
			color: #000;
			font-size: 16px;
			font-weight: 400;
			border: none;
			resize: none;
			padding-left: 15px;
			padding-top: 5px;
			height: 138px;
			background-color: #f5f5f5;
		}
		.btn{
			margin-top: 10px;
			width: 164px;
			height: 34px;
			line-height: 34px;
		}
	}
}
.about{
	font-size: 0px;
	img.pull-left{
		margin-right: 30px;
	}
	p{
		color: #000;
		font-size: 16px;
		font-weight: 400;
	}
	ul.about-list{
		display: block;
		margin-top: 19px;
 		margin-left: 98px;
		li{
			color: #000;
			font-size: 16px;
			font-weight: 400;
			position: relative;
			text-align: left;
			margin-bottom: 15px;
			strong{
				display: block;
				font-weight: 600;
			}
			a{
				color: #2f5c9a;
				font-weight: 600;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
			&:nth-of-type(1){
				&:before{
					position: absolute;
					left: -81px;
					content: '';
					top: 0px;
					background: url(../images/ico-about-list-1.png) center center no-repeat;
					width: 46px;
					height: 46px;
				}
				
			}
			&:nth-of-type(2){
				&:before{
					position: absolute;
					left: -83px;
					content: '';
					top: 0px;
					background: url(../images/ico-about-list-2.png) center center no-repeat;
					width: 47px;
					height: 55px;
				}
				
			}
			&:nth-of-type(3){
				&:before{
					position: absolute;
					left: -72px;
					content: '';
					top: 0px;
					background: url(../images/ico-about-list-3.png) center center no-repeat;
					width: 27px;
					height: 48px;
				}
				
			}
		}
	}
}
.project{
	font-size: 0px;
	h3{
		margin-bottom: 15px;
	}	
	.project-box{
		margin-bottom: 94px;
		position: relative;
		&:before{
			position: absolute;
			content: '';
			left: 0;
			right: 0;
			bottom: -39px;
			height: 1px;
			background-color: #dedede;
		}
		&:after{
			display: block;
			content: '';
			 clear: both;
		}
		.img-wr{
			float: right;
			width: 208px;
			height: 422px;
			text-align: center;
			padding-top: 45px;
			background: url(../images/img-project-wr.png) center center no-repeat;
			margin-right: 24px;
			margin-left: 65px;
		}
		.pr-ttl{
			color: #2f5c9a;
			font-size: 18px;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 3px;
			&.red{
				color: #d83b2b;
			}
		}
		.pr-desc{
			color: #000;
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
		}
		ul{
			padding-left: 33px;
  			margin-top: 30px;
			li{
				color: #000;
				font-size: 16px;
				font-weight: 400;
				position: relative;
				line-height: 20px;
				margin-bottom: 18px;
				&:before{
					background: url(../images/ico-project-list.png) center center no-repeat;
					position: absolute;
					content: '';
					width: 18px;
					height: 18px;
					top: 5px;
    				left: -32px;
				}
			}
		}
		.btn{
			width: 164px;
			height: 34px;
			line-height: 34px;
		}
	}
	.left-side{
		.project-box{
			&:before{
				display: none;
			}
		}
	}
	.work{
		display: inline-block;
		width: 350px;
		margin-right: 20px;
	}
	.make-order{
		width: 830px;
		height: 190px;
		border: 1px solid #2f5c9a;
		color: #2f5c9a;
		font-size: 24px;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;
		margin-left: 9px;
		padding-top: 40px;
		line-height: 29px;
		margin-bottom: 50px;
		.btn{
			display: block;
			width: 235px;
			height: 42px;
			line-height: 42px;
			margin: 23px auto 23px;
		}
	}
}