@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,700);

@font-face {
	font-family: 'Gotham Pro';
	src: url('../fonts/GothamPro-Bold.eot');
	src: url('../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GothamPro-Bold.woff2') format('woff2'),
		url('../fonts/GothamPro-Bold.woff') format('woff'),
		url('../fonts/GothamPro-Bold.ttf') format('truetype'),
		url('../fonts/GothamPro-Bold.svg#GothamPro-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}
