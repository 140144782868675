#sucsess{
  display: none;
  width: 300px;
  padding: 0 40px;
  background-color: #fff;
  border-radius: 3px;
  p{
    font-size: 28px;
    text-align: center; 
    color: #000;
  }
}
.btn{
  display: inline-block;
  border: none;
  vertical-align: middle;
  text-align: center;
  background-color: #2f5c9a;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  z-index: 10;
  &:hover{
    opacity: 0.8;
  }

}
h2{
  display: inline-block;
  position: relative;
  color: #2e2e2e;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  &:before{
    position: absolute;
    content: '';
    left: -63px;
    top: 19px;
    background: url(../images/h2-before.png) center center no-repeat;
    width: 55px;
    height: 7px;
  }
  &:after{
    position: absolute;
    content: '';
    right: -63px;
    top: 19px;
    background: url(../images/h2-after.png) center center no-repeat;
    width: 55px;
    height: 7px;
  }
}
.navigation{
  margin-top: 49px;
   font-size: 15px;
  font-weight: 600;
  li{
    display: inline-block;

    a{
      font-size: 15px;
      font-weight: 600;
      position: relative;
      color: #3b3b3b;
      text-decoration: underline;
      padding-right: 5px;
      &:hover{
        text-decoration: none;
      }
      &.this{
        color: #2f5c9a;
        padding-left: 5px;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  
  }
}
h3{
  color: #2f5c9a;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 27px 0 21px;
}
.left-side{
  width: 848px;
  margin-right: 30px;
  display: inline-block;
  vertical-align: top;
}
.right-side{
  display: inline-block;
  vertical-align: top;
  width: 262px;
  margin-top: 31px;
  .right-ttl{
    color: #2f5c9a;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    line-height: 22px;
    margin-bottom: 33px;
    margin-top: 15px;
    &.big{
       margin-bottom: 40px;
       margin-top: 0px;
      &:after{
        bottom: -21px;
      }
    }
    &:after{
      position: absolute;
      content: '';
      bottom: -10px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #2f5c9a;
    }
  }
  
  .list-work{
    margin-top: 20px;
    display: block;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}
.work{
    display: block;
    margin-bottom: 63px;
    &:after{
      display: block;
      content: '';
      clear: both;
    }
    .img-wr{
      float: left;
      text-align: center;
      background: url(../images/our-work-wr.png) center center no-repeat;
      width: 125px;
      height: 254px;
      padding-top: 29px;
      margin-right: 17px;
    }
    .work-ttl{
      color: #d83b2b;
      font-size: 16px;
      font-weight: 600;
      margin-top: 6px;
      margin-bottom: 10px;
      text-align: center;
    }
    .desc{
      color: #2e2e2e;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 15px;
    }
    .more{
      color: #2f5c9a;
      font-size: 14px;
      font-weight: 600;
      &:hover{
        text-decoration: underline;
      }
    }
  }