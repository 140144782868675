* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  outline: none !important;
  text-decoration: none;
  box-sizing: border-box;
  max-height: 9999px;
}
html, * html body {
  height: 100%;
}
h1,h2,h3,h4,h5,h6{
  margin: 0;
}
body{
  min-height: 100%;
  min-width: 1170px;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}
.clr {
  clear: both !important;
  height: 0;
  display: block !important;
}
.pull-left{
  float: left;
}
.pull-right{
  float: right;
}
.fwidth{
  width: 1170px;
  margin: auto;
  padding: 0 15px;
}
#wrapper{
  min-width: 1170px;
  overflow: hidden;
}
a,button{
  -webkit-transition: all .1s;
  -moz-transition: all .1s;
  -ms-transition: all .1s;
  -o-transition: all .1s;
  transition: all .1s;
}
input.error,textarea.error{
  box-shadow: inset 0 0 5px red !important;
}
::-webkit-input-placeholder {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
::-moz-placeholder {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
:-ms-input-placeholder {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
input:-moz-placeholder {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
input,textarea{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}